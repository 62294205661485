import React, { Fragment } from "react";
import Footer from "../../components/footer/Footer";
import Hero from "../../components/hero/hero";
import Navbar from "../../components/Navbar/Navbar";
import Scrollbar from "../../components/scrollbar/scrollbar";
import About from "../../components/about/about";
import MissionVission from "../../components/MissionVission/MissionVission";
import ServiceSection from "../../components/ServiceSection/ServiceSection";
import ServiceSectionS3 from "../../components/ServiceSectionS3/ServiceSectionS3";
import PartnerSection from "../../components/PartnerSection/PartnerSection";
import NoticePopup from "../../components/NoticePopup/noticePopup";

const HomePage = () => {
  return (
    <Fragment>
      <Navbar hclass={"wpo-header-style-1"} topbarNone={"topbar-none"} />
      <Hero />
      <About />
      <NoticePopup />
      <MissionVission />
      <ServiceSection />
      <ServiceSectionS3 />
      <PartnerSection />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default HomePage;
