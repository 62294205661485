import React, { useState, useEffect } from "react";
import "./style.css"; // 스타일 파일 가져오기

const NoticePopup = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const hideUntil = localStorage.getItem("popupHideUntil");
    if (!hideUntil || new Date() > new Date(hideUntil)) {
      setIsVisible(true);
    }
  }, []);

  const closePopup = () => {
    setIsVisible(false);
  };

  const closeFor24Hours = () => {
    const hideUntil = new Date();
    hideUntil.setHours(hideUntil.getHours() + 24); // 현재 시간에서 24시간 후
    localStorage.setItem("popupHideUntil", hideUntil.toISOString());
    setIsVisible(false);
  };

  if (!isVisible) {
    return null; // 팝업이 숨겨지면 아무것도 렌더링하지 않음
  }

  return (
    <div className="popup-container">
      <div className="popup">
        <h1 className="title">분할합병공고</h1>
        <p className="sub-title">(채권자 이의 및 주권 제출 공고)</p>
        <p className="content">
          (주)디메타(이하“갑”이라 한다)와 남부솔루션(주)(이하“을”이라 한다)는
          2024년 11월 15일 각사의 임시주주총회에서 “을”의 정보통신공사업을
          분할하여 그 분할된 재산으로 “갑”과 분할합병하고, “을”은 존속하기로
          결의하였으며, 상법 제530조의9제3항의 절차를 밟아 결의하였으므로 이
          회사 분할합병에 이의가 있는 채권자는 본 공고 게재일로부터 1개월 내에
          본 회사에 이의를 제출하시기 바라며, 또한 각사의 주주는 해당사에 주권을
          제출하시기 바라와 이에 공고함.
        </p>
        <p className="date">2024년 11월 20일</p>
        <div className="section">
          <strong className="info-text">“갑”</strong>
          <ul className="info-list">
            <li className="info-item">
              <span className="info-label">상 호:</span>
              <span className="info-text">(주)디메타</span>
            </li>
            <li className="info-item">
              <span className="info-label">소 재 지:</span>
              <span className="info-text">
                서울특별시 강남구 테헤란로92길 39, 2층(대치동, 동해빌딩)
              </span>
            </li>
            <li className="info-item">
              <span className="info-label">사내이사:</span>
              <span className="info-text">강병관</span>
            </li>
          </ul>
        </div>
        <div className="section">
          <strong className="info-text">“을”</strong>
          <ul className="info-list">
            <li className="info-item">
              <span className="info-label">상 호:</span>
              <span className="info-text">남부솔루션(주)</span>
            </li>
            <li className="info-item">
              <span className="info-label">소 재 지:</span>
              <span className="info-text">
                전남 나주시 왕곡면 혁신산단로 160
              </span>
            </li>
            <li className="info-item">
              <span className="info-label">대표이사:</span>
              <span className="info-text">추호철</span>
            </li>
          </ul>
        </div>
        <div className="footer">
          <button className="button" onClick={closeFor24Hours}>
            24시간 동안 다시 열람하지 않습니다.
          </button>
          <button className="button" onClick={closePopup}>
            닫기
          </button>
        </div>
      </div>
    </div>
  );
};

export default NoticePopup;
